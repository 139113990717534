import React, { useEffect, useState } from "react";
import { useEditorContext } from "../../../providers/EditorProvider";

import { Button, Collapse, Select, Switch, Avatar } from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as AddSquare } from '../../../assets/add-square-filled.svg'
import { ReactComponent as Laptop } from '../../../assets/laptop.svg'
import { ReactComponent as Tablet } from '../../../assets/tablet.svg'
import { ReactComponent as Mobile } from '../../../assets/mobile.svg'

import CreatePannel from "../modal/CreatePannel";
import EditPanel from "../panels/EditPanel";
import PanelMenuItem from "../panels/PanelMenuItem";

const { Panel } = Collapse

const defaultPosition = {
    mobile: 'bottom',
    tablet: 'bottom',
    desktop: 'bottom'
}

const defaultScroll = {
    tablet: false,
    desktop: false
}

export default function Panels() {

    const [editorState, editorDispatch] = useEditorContext()
    const { updatePanelsProperty, getFeatureName, deletePanel, updatePanel } = editorDispatch;
    const [isCreating, setIsCreating] = useState(false)
    const [isEditing, setIsEditing] = useState(null)
    const [position, setPosition] = useState(editorState?.panels?.position || defaultPosition)
    const [scroll, setScroll] = useState(editorState?.panels?.scroll || defaultScroll)
    const [needUpdate, setNeedUpdate] = useState(0)

    useEffect(() => {
        setPosition(editorState?.panels?.position || defaultPosition)
        setScroll(editorState?.panels?.scroll || defaultScroll)
        checkEditPanel()
    }, [editorState.reloadMap])
    
    useEffect(() => {
        if (needUpdate)
            updatePanelsProperty({ position, scroll })
    }, [needUpdate])

    function checkEditPanel() {
        if (isEditing) {
            console.log(isEditing)
            let panelMatch = editorState.panels[isEditing.type].find(item => item.id === isEditing.panel.id)
            if (!panelMatch) {
                setIsEditing(null)
            } else {
                setIsEditing(prev => { return {...prev, panel: panelMatch}})
            }
        }
    }

    function handleCreate() {
        setIsCreating(true)
    }

    function handleUpdate(setter, property, value) {
        setter(prev => { return { ...prev, [property]: value } })
        setNeedUpdate((new Date()).getTime())
    }

    function handleDelete(e, id, type) {
        e.stopPropagation()
        deletePanel(id, type)
    }

    function handleVisible(e, panel, type, visible) {
        e.stopPropagation()
        let pan = { ...panel, visible }
        updatePanel(pan, type)
    }

    function handleSelect(panel, type) {
        setIsEditing({ type, panel })
    }

    if (isEditing) return (
        <EditPanel panel={isEditing?.panel} type={isEditing?.type} setVisible={setIsEditing} />
    )

    return (
        <div >
            <div className="pd-default ">
                <h3 className="no-margin">Liste de vos panneaux</h3>
                <p className="light">Les panneaux sont les éléments qui permettent d’afficher les contenus de votre cartographie.</p>
            </div>
            <div>
                {
                    (editorState.panels.select.length > 0 || editorState.panels.static.length > 0) &&
                    <>
                        {
                            editorState.panels.static.map(panel =>
                                <PanelMenuItem type="static" key={panel.id} panel={panel} getFeatureName={getFeatureName} handleSelect={handleSelect} handleVisible={handleVisible} handleDelete={handleDelete} />
                            )
                        }
                        {
                            editorState.panels.select.map(panel =>
                                <PanelMenuItem type="select" key={panel.id} panel={panel} getFeatureName={getFeatureName} handleSelect={handleSelect} handleVisible={handleVisible} handleDelete={handleDelete} />
                            )
                        }
                    </>
                }
                <div style={{ textAlign: 'center', margin: '1em 0' }}>
                    <Button size='large'
                        className="button-white primary"
                        onClick={() => handleCreate(null)}
                        icon={<Icon component={AddSquare} />}>
                        Créer un panneau
                    </Button>
                </div>
            </div>

            <Collapse defaultActiveKey={['position']} ghost expandIconPosition="right" accordion>
                <Panel header={<strong>Position des panneaux</strong>} key='position'>
                    <div className="flex align-center" style={{ padding: '1em 0', borderBottom: '2px solid #f7f7f7' }}>
                        <Avatar
                            style={{ backgroundColor: '#2494d1', marginRight: '1em' }}
                            icon={<Icon component={Laptop} />} />

                        <div style={{ flex: 1 }}>
                            <p>Sur pc</p>
                            <div>
                                <Select value={position.desktop} style={{ width: "90%" }} onChange={(value) => handleUpdate(setPosition, 'desktop', value)}>
                                    <Select.Option value="top">En haut</Select.Option>
                                    <Select.Option value="bottom">En bas</Select.Option>
                                    <Select.Option value="left">A gauche</Select.Option>
                                    <Select.Option value="right">A droite</Select.Option>
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="flex align-center" style={{ padding: '1em 0', borderBottom: '2px solid #f7f7f7' }}>
                        <Avatar
                            style={{ backgroundColor: '#2494d1', marginRight: '1em' }}
                            icon={<Icon component={Tablet} />} />

                        <div style={{ flex: 1 }}>
                            <p>Sur tablette</p>
                            <div>
                                <Select value={position.tablet} style={{ width: "90%" }} onChange={(value) => handleUpdate(setPosition, 'tablet', value)}>
                                    <Select.Option value="top">En haut</Select.Option>
                                    <Select.Option value="bottom">En bas</Select.Option>
                                    <Select.Option value="left">A gauche</Select.Option>
                                    <Select.Option value="right">A droite</Select.Option>
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="flex align-center" style={{ padding: '1em 0', borderBottom: '2px solid #f7f7f7' }}>
                        <Avatar
                            style={{ backgroundColor: '#2494d1', marginRight: '1em' }}
                            icon={<Icon component={Mobile} />} />

                        <div style={{ flex: 1 }}>
                            <p>Sur mobile</p>
                            <div>
                                <Select value={position.mobile} style={{ width: "90%" }} onChange={(value) => handleUpdate(setPosition, 'mobile', value)}>
                                    <Select.Option value="top">En haut</Select.Option>
                                    <Select.Option value="bottom">En bas</Select.Option>
                                </Select>
                            </div>
                        </div>
                    </div>
                </Panel>
                <Panel header={<strong>Défilement dans les panneaux</strong>} key='scroll'>
                    <div>
                        <div className="flex align-center" style={{ padding: '1em 0', borderBottom: '2px solid #f7f7f7' }}>
                            <Avatar
                                style={{ backgroundColor: '#2494d1', marginRight: '1em' }}
                                icon={<Icon component={Laptop} />} />
                            <div style={{ flex: 1 }}>
                                <p>Sur pc</p>
                                <span>
                                    <Switch checked={scroll.desktop}
                                        size="small"
                                        onChange={(value) => handleUpdate(setScroll, 'desktop', value)} />
                                </span>
                            </div>
                        </div>
                        <div className="flex align-center" style={{ padding: '1em 0', borderBottom: '2px solid #f7f7f7' }}>
                            <Avatar
                                style={{ backgroundColor: '#2494d1', marginRight: '1em' }}
                                icon={<Icon component={Tablet} />} />

                            <div style={{ flex: 1 }}>
                                <p>Sur tablette</p>
                                <span>
                                    <Switch checked={scroll.tablet}
                                        size="small"
                                        onChange={(value) => handleUpdate(setScroll, 'tablet', value)} />
                                </span>
                            </div>
                        </div>
                    </div>
                </Panel>
            </Collapse>
            <CreatePannel visible={isCreating} setVisible={setIsCreating} />
        </div >
    )
}